
    <div class="theme-footer" id="mainFooter">
        <div class="container _ph-mob-0">
          <div class="row row-eq-height row-mob-full" data-gutter="60">
            <div class="col-md-2">
              <div class="theme-footer-section theme-footer-">
                <a class=" _mb-mob-30" href="#">
                  <img src="assets/img/kbw.webp" alt="buupass.com" title="Image Title"/>
                </a>
                <div class="theme-footer-brand-text _mt-20">
                  <a href="https://www.instagram.com/kenya_bus_world/" >
                    <svg class="Icon__StyledIcon-sc-1pnzn3g-0 icon-cl" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 01-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 017.8 2zm-.2 2A3.6 3.6 0 004 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 003.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6zm9.65 1.5a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zM12 7a5 5 0 110 10 5 5 0 010-10zm0 2a3 3 0 100 6 3 3 0 000-6z"></path></svg>
                  </a>
                  <a href="https://www.facebook.com/kenyasbusworld001" >
                    <svg class="Icon__StyledIcon-sc-1pnzn3g-0 icon-cl" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M5 3h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2zm13 2h-2.5A3.5 3.5 0 0012 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 011-1h2V5z"></path></svg>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-4">
                  <div class="theme-footer-section theme-footer-">
                    <!-- <h5 class="theme-footer-section-title">The Company</h5> -->
                    <ul class="theme-footer-section-list">
                      <li>
                        <a href="/about-us.html">About us</a>
                      </li>
                      <li>
                        <a href="https://buupass.com/contact-us.html">Contact us</a>
                      </li>
                      <li>
                        <a target="_blank"  href="/BuuPass_Website_Terms_of_Use.pdf ">Terms & Conditions </a>
                      </li>
                      <li>
                        <a target="_blank"  href="/BuuPass_Website_Privacy_Policy.pdf ">Privacy Policy</a>
                      </li>
            
                    </ul>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">Top Routes</h5>
                    <ul class="theme-footer-section-list">
                      <li>
                        <a (click)="clickNairobiTl()"  href="/nairobi-kitale">Nairobi - Kitale</a>
                      </li>
                      
                      <li>
                        <a (click)="clickNairobiMb()" >Nairobi - Malaba</a>
                      </li>
                      <li>
                        <a (click)="clickNairobiBg()" >Nairobi - Bungoma</a>
                      </li>

                    </ul>
                  </div>
                </div>
              
                <div class="col-md-4">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">Popular Destinations</h5>
                    <ul class="theme-footer-section-list">
                      <li>
                        <p>Nairobi</p>
                      </li>
                      <li>
                        <p>Kitale</p>
                      </li>
                      <li>
                        <p>Bungoma</p>
                      </li>
                      <li>
                        <p>Malaba</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
