
<div class=" hidden-md hidden-lg ">
  <app-nav ></app-nav>
</div>
<div class="visible-lg visible-md">
<app-headerhome ></app-headerhome>
</div>
<div >
<div class="MainView _pos-r">
  <!-- <div class="xmas-img visible-lg visible-md" style="">
    <img height="250" src="assets/img/brands/xmas.png">
  </div>
  <div class="xmas-img-right visible-lg visible-md" style="">
    <img height="250" src="assets/img/brands/xmas-1.png">
  </div>
  <div class="xmas-img-right  hidden-md hidden-lg" style="">
    <img height="70" src="assets/img/brands/xmas-1.png">
  </div> -->
  <div class=" hidden-md hidden-lg ">
      <app-search-form></app-search-form>
  </div>
  
    <div  class="theme-hero-area-body _mt-90 visible-lg visible-md _w-f buupass-container" >
          <div class="theme-search-area-tabs ">
            <div class="theme-search-area-tabs-header  _mr-20 mt-10">
              <div class="hidden-xs">
              <h1 class="  banner-text xmax-view "> Book buses all over kenya.</h1>
              <img class=" hidden-md hidden-lg _ml-50" src="assets/img/company1/logo-mobile.webp" alt="buupass.com" alt="Image Alternative text" title="Image Title"/>
        </div>
        
            </div>
            <div class="tabbable " #stickyForm  [ngClass]="sticky ? 'sticky':'' " >
              <div class="tab-content   _bg-w tab-content-curved">
                <div class="tab-pane active" id="SearchAreaTabs-4" >
                  <div class="theme-search-area " >
                      <form  autocomplete="off" novalidate [formGroup]="reactiveForm" (ngSubmit)="submitForm()">
                        <div class="row" >
                              <div class="col-md-3">
                                  <app-from formControlName="pickup"
                                    [options]="data"
                                    [ngClass]="{ 'ng-invalid-custom': submitted && f.pickup.errors }"
                                    [placeholder]="fromPlaceholder"
                                    [isReadOnly]="isReadOnly">
                                  </app-from>
                                  <span *ngIf="submitted && f.pickup.errors" [@slideInOut] class="invalid-select">Please enter depature location
                                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                  </span>
                              </div>
                              <div class="col-md-3">
                                  <app-dropdown
                                    formControlName="dropoff"
                                    [dropdownoptions]="data"
                                    [ngClass]="{ 'ng-invalid-custom': submitted && f.dropoff.errors }"
                                    [placeholder]="toPlaceholder"
                                    [isReadOnly]="isReadOnly">
                                  </app-dropdown>
                                  <span *ngIf="submitted && f.dropoff.errors" [@slideInOut] class="invalid-select">Please enter arrival location
                                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                  </span>
                              </div>
                              <div class="col-md-2">
                                <div class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white  theme-search-area-section-mr">
                                  <div class="theme-search-area-section-inner">
                                    <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>
                                    <input type="text"
                                    formControlName="traveldate"
                                    placeholder="Travel Date"
                                    [minDate]="bsValue"
                                    (bsValueChange)="onTravelDateChange($event)"
                                    class="theme-search-area-section-input "
                                    bsDatepicker
                                    [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-2 ">
                              <div class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white">
                                <div class="theme-search-area-section-inner theme-search-area-section-inner-return">

                                          <div *ngIf="returning" class="theme-search-area-section-inner returndate">
                                            <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>

                                            <i [ngClass]="( isClicked ) ? 'hide-returnicon':''" class="theme-search-area-section-icon lin lin-calendar "></i>
                                            <input  autocomplete="off" type="text"
                                            placeholder="DD-MM-YYYY"
                                            class="theme-search-area-section-input"
                                            formControlName="returndate"
                                            [minDate]="today"
                                            #dp="bsDatepicker"
                                            [isOpen]="isOpen"
                                            bsDatepicker
                                            [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                                            >

                                            <i (click)="closeReturn()" [ngClass]="( isClicked ) ? 'hide-returnicon':''"  class="times-icon  fa fa-times"></i>
                                    </div>
                                    <button *ngIf="!returning" (click)="returnClicked()" class="flix-btn-arrow  DatePlaceholder__button___3XZ5p" type="button"> <i class=" icon-flix fa fa-plus "></i> Add Return
                                    </button>
                              </div>
                            </div>
                          </div>
                              <div class="col-md-2">
                                <button type="submit"  class="theme-search-area-submit btn-block theme-search-area-submit-curved  theme-search-area-submit-glow">SEARCH</button>
                              </div>
                      </div>
                      </form>
                </div>
                <div class="text-center" style="">
                  <img  height="60" alt="" class="text-center " src="assets/img/pay_via.webp">
              </div>
              
              </div>
            </div>
            </div>
        </div>
    </div>

    <div class="theme-page-section visible-md visible-lg _mt-10 theme-page-section-sm ">
        <div class="theme-page-section-header theme-page-section-header-white">
          <div class="buupass-home-section">
          <div class="buupass-container">
              <div class="feature-list">
                  <div class="list-item">
                    <div class="row">
                        <div class="col-md-2 col-xs-2">
                            <img src="assets/img/company1/payments.webp" class="_mt-20" style="height: 45px;"/>
                        </div>
                        <div class="col-md-8 col-xs-8">
                          <h5 class="font-desc">Flexible payments</h5>
                          <p class="content">Pay with M-pesa, credit cards or bank transfers. We have you fully covered.</p>
                        </div>
                   </div>
                  </div>
                  <div class="list-item">
                    <div class="row">
                        <div class="col-md-2 col-xs-2">
                            <img src="assets/img/company1/support.webp" class="_mt-20" style="height: 45px;"/>
                        </div>
                        <div class="col-md-8 col-xs-8">
                          <h5 class="font-desc">Great customer care</h5>
                          <p class="content">Get excellent customer service available 8:00 AM to 10:00 PM, via phone, chat or email.</p>
                        </div>
                   </div>
                  </div>
                  <div class="list-item">
                    <div class="row">
                        <div class="col-md-2 col-xs-2">
                            <img src="assets/img/company1/convenience.webp" class="_mt-20" style="height: 45px;"/>
                        </div>
                        <div class="col-md-8 col-xs-8">
                          <h5 class="font-desc">Enjoy convenience</h5>
                          <p class="content">Book anytime from the convenience of your office, home, school,  or market.</p>
                        </div>
                   </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>

        <!-- mobile implementation -->
        <div class="row hidden-lg hidden-md ">
          <div class="col-md-3 col-lg-3 ">
                <h5 class="text-center">Customers</h5>
                <h2 class=" text-center text-green text-stats">2.5M+</h2>
                <p class="text-center text-mobile">Total customers.</p>
          </div>
            <div class="col-md-3 col-lg-3">
                <h5 class="text-center">Buses</h5>
              <h2 class=" text-center text-green text-stats">1250+</h2>
              <p class="text-center text-mobile">Total buses in operations</p>
           </div>
            <div class="col-md-3 col-lg-3">
                <h5 class="text-center">Routes</h5>
              <h2 class=" text-center text-green text-stats">800+</h2>
              <p class="text-center text-mobile">Total routes covered </p>
           </div>
            <div class="col-md-3 col-lg-3">
                <h5 class="text-center">Tickets</h5>
              <h2 class=" text-center text-green text-stats">9M+</h2>
              <p class="text-center text-mobile">Bus tickets sold</p>
            </div>
      </div>
      <!-- mobile implementation -->


    <div class="theme-hero-area _mt-10">
      <div class="theme-hero-area-body">
        <div class="theme-page-section theme-page-section-buupass theme-page-section">
          <div class="buupass-container">
            <div class="theme-page-section-header _ta-l">
              <h2 class="destinationHeading">Top Destinations</h2>
              <p class="desSubHeading">These destinations are popular among travelers like you</p>
            </div>
            <div class="row">
                <div class="col-md-12">
                  <div class="row row-col-gap" data-gutter="10">
                    <div (click)="nairobiKisumu()" class="col-md-5 change-city">
                      <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                        <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/kisumu1.webp);"></div>
                        <div class="banner-mask"></div>
                        <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                          <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                             <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                             </div>
                              <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                          </h3>
                              <h5 class="banner-title">Kisumu</h5>
                              <p class="banner-subtitle">Kenya</p>
                        </div>
                      </div>
                    </div>
                    <div (click)="nairobiKitale()" class="col-md-3 change-city">
                      <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                        <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/kitale.webp);"></div>
                        <div class="banner-mask"></div>
                        <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                          <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                             <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                             </div>
                              <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                          </h3>
                              <h5 class="banner-title">Kitale</h5>
                              <p class="banner-subtitle">Kenya</p>
                        </div>
                      </div>
                    </div>
                    <div  (click)="nairobiBungoma()" class="col-md-4 change-city">
                      <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                        <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/bungoma.webp);"></div>
                        <div class="banner-mask"></div>

                        <div class="banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                          <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                             <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                             </div>
                              <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                          </h3>
                          <h5 class="banner-title">Bungoma</h5>
                          <p class="banner-subtitle">Kenya</p>
                        </div>
                      </div>
                    </div>
                    <div (click)="nairobiKampala()" class="col-md-4 change-city">
                      <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                        <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/kampala.webp);"></div>
                        <div class="banner-mask"></div>
                        <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                          <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                             <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                             </div>
                              <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                          </h3>
                              <h5 class="banner-title">Kampala</h5>
                              <p class="banner-subtitle">Uganda</p>
                        </div>
                      </div>
                    </div>
                    <div  (click)="nairobiMombasa()" class="col-md-5 change-city">
                      <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                        <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/mombasa.webp);"></div>
                        <div class="banner-mask"></div>
                        <div class="banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                          <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                             <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                             </div>
                              <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                          </h3>
                          <h5 class="banner-title">Mombasa</h5>
                          <p class="banner-subtitle">Kenya</p>
                        </div>
                      </div>
                    </div>
                    <div (click)="nairobiBusia()" class="col-md-3 change-city">
                      <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                        <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/busia.webp);"></div>
                        <div class="banner-mask"></div>
                        <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                          <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                             <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Nairobi&nbsp;</div>
                             </div>
                              <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                          </h3>
                              <h5 class="banner-title">Busia</h5>
                              <p class="banner-subtitle">Kenya</p>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
          </div>

          <div class="theme-page-section theme-page-section-sm visible-lg visible-md">
            <div class="buupass-container">
              <div class="theme-page-section-header theme-page-section-header-white">
                <div class="buupass-home-section">
                  <div class="feature-list">

                      <div class="list-item">
                        <div class="row">

                            <div class="col-md-8 col-xs-8">
                                <h5 class="text-center">Customers</h5>
                              <h2 class=" text-center text-green text-stats">2.5M+</h2>
                              <p class="text-center">Total customers.</p>
                            </div>
                       </div>
                      </div>
                      <div class="list-item">
                        <div class="row">
                          <div class="col-md-8 col-xs-8">
                              <h5 class="text-center">Buses</h5>
                            <h2 class=" text-center text-green text-stats">1250+</h2>
                            <p class="text-center">Total buses in operations</p>
                          </div>
                       </div>
                      </div>
                      <div class="list-item">
                        <div class="row">
                          <div class="col-md-8 col-xs-8">
                              <h5 class="text-center">Routes</h5>
                            <h2 class=" text-center text-green text-stats">800+</h2>
                            <p class="text-center">Total routes covered </p>
                          </div>
                       </div>
                      </div>
                      <div class="list-item">
                        <div class="row">
                          <div class="col-md-8 col-xs-8">
                              <h5 class="text-center">Tickets</h5>
                            <h2 class=" text-center text-green text-stats">9M+</h2>
                            <p class="text-center">Bus tickets sold</p>
                          </div>
                       </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
  </div>
</div>


<app-footer></app-footer>
