<div id="tophome"></div>
<div class="header-mobile">
    <nav class="mobile-nav">
      <div class="mobile-item-cntnr ">
        <a  class="navbar-brand-logo _mt-10" href="index.html">
          <img  src="assets/img/kbw.webp" alt="buupass.com" width="40" alt="Image Alternative text" title="Image Title"/>
        </a>
      </div>

        <!-- <div class="mobile-item-custom ">
              <p class="f-15 text-black">Kenya Bus World</p>             
        </div> -->

        
        <div class="nav-border"></div>
    </nav>
</div>
